<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>
  <div id="maincontent_container">

    <h3>Reset Password</h3>
    <spinner v-if="loading" />

    <div v-if="!loading">
      <div v-if="message">
        <div class="alert alert-success" role="alert">
          {{ message }}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>
    <div v-else>
      <form @submit.prevent="handleSubmit">
        <div class="col-3 mb-1">
            <input type="text" v-model="email" class="form-control" required placeholder="Email address">
        </div>
        <br/> 
        <button class="btn btn-primary">Submit</button>
      </form>
    </div>   
    </div>

    
  </div>
</template>

<script>
import { ref } from 'vue'
import { getAPI } from '../../utils/axios-api'
import Spinner from '../../components/Spinner.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"

export default {
    name: "ResetPasswordLink",
    components: {Spinner, BreadcrumbPage},
    setup() {
      const email = ref('')
      const message = ref(null)
      const error = ref(null)
      const loading = ref(false)

      //breadcrumb
      const breadcrumb_links = ref([])
      breadcrumb_links.value.push({text: "Home", link: "Home"})
      breadcrumb_links.value.push({text: "Reset Password", link: "ResetPasswordLink"})

      const handleSubmit = async () => {
        loading.value = true
        var url = "/account/password/reset/"
        getAPI.post(url, { email: email.value })
        .then((response) => {
          console.log(response)
          message.value = response.data.message + " (The link will be expired in 30 minutes.)"
          loading.value = false
        })
        .catch(err => {
          console.log(err.response)
          message.value = err.response.data.message +  " (The link will be expired in 30 minutes.)"
          loading.value = false      
        })
        
      }

      return { email, handleSubmit,message,loading, breadcrumb_links }

    }
}
</script>

<style>

</style>